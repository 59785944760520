const palette = {
  colorWhite: "rgba(255,255,255,1)",
  colorBlack: "#30383F",
  fontSemiSofia: "SofiaProSemiBoldAz, sans-serif",
  fontSofiaRegular: "SofiaProRegularAz, sans-serif",
  fontSofia: "SofiaProRegularAz, sans-serif",
  fontSofiaProBold: "SofiaProBoldAz, sans-serif",
  fontSofiaProBLack: "SofiaProBlackAz, sans-serif",
  fontSofiaProLight: "SofiaProLightAz, sans-serif",
  fontSofiaProMedium: "SofiaProMediumAz, sans-serif",
  fontCooperRegular: "CooperRegular, sans-serif",
  fontCooperBlack: "CooperBlack, sans-serif",
  fontCooperLight: "CooperLight, sans-serif",
  fontCooperMedium: "CooperMedium, sans-serif",
  fontSofiaBold: "SofiaProBoldAz, sans-serif",
  colorGrayLight: "rgba(155,155,155,.7)",
  colorPurple: "#59267A",
  lightBS: "rgba(0, 0, 0, 0.08) 0px 3px 10px",
  letterColor: "#02C1B3",
  boxShadowUp: "0px 0px 14px 2px rgba(0,38,35,0.1)",
  colorInput: "rgba(249,247,255,1)",
  letterColorGrayLight: "rgba(48,56,63,.61)",
  colorGreenLight: "rgba(174,224,221,.61)",
  grayLight: `rgba(48,56,63,.19)`,
  colorBlueLight: "rgba(247,255,254)",
}

export default palette
