const React = require("react")
const { GlobalStyles } = require("./src/styles")
const { Helmet } = require("react-helmet")

exports.wrapRootElement = ({ element }) => {
  return (
    <>
      <Helmet>
        <script
          type="module"
          src="https://cdn.jsdelivr.net/npm/@leadsales/leadclick@1.11.1/dist/leadclick/leadclick.esm.js"
        ></script>
      </Helmet>
      <leadclick-widget
        integrations="1"
        fburl="https://www.m.me/"
        igurl="https://www.instagram.com/leadsales.io/?hl=es"
        waurl="https://api.whatsapp.com/send?phone=5213337872943&text=%C2%A1Hola!%20Tengo%20una%20duda%20respecto%20a%20sus%20servicios."
        cta="Contáctanos"
        fontcolor="#ffffff"
        bgcolor="#59267A"
        instructions="Cuál es la mejor manera para contactarte?"
        orientation="right"
        visible_integrations="WhatsApp"
        ispremium="false"
      />
      <GlobalStyles />
      {element}
    </>
  )
}
